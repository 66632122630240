import React, { Fragment } from 'react'
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";

const MensagemDivergenciaProfessor = ({ divergencia }) => (

    divergencia.divergenciaDisponibilidade &&
    <li>
        {divergencia.professor.nome_social}
    </li>

)
const MensagemDivergencia = ({ divergencia }) => (
    <Fragment>
        {
            divergencia.semLocal &&
            <p>
                Sem local para esta aula
            </p>
        }
        {
            divergencia.semProfessor &&
            <p>
                Sem professor para esta aula
            </p>
        }
        {
            divergencia.divergenciaServico &&
            <p>
                Sem serviço para esta aula
            </p>
        }
        {
            divergencia.divergenciaQtdLocal &&
            <p>
                Quantidade de alunos no local da aula
            </p>
        }
        {
            divergencia.divergenciaQtdAulas &&
            <p>
                Existem mais aulas que ofertas
            </p>
        }
        {
            divergencia.divergenciaQtdOfertas &&
            <p>
                Existem mais ofertas que aulas
            </p>
        }
        {
            divergencia.horasDisponiveis &&
            <Fragment>
                Hora Ociosa:
                <ul>
                    <li>1 hora</li>
                </ul>
            </Fragment>
        }
    </Fragment>
)
const PopOverCriticas = ({ id, logCriticas }) => {
    return (
        <UncontrolledPopover trigger="hover" placement="top" target={`pop${id}`} style={{ width: '360px' }}>
            <PopoverHeader >Divergências</PopoverHeader>
            <PopoverBody>
                <div className='logs-historico'>
                    {logCriticas?.map((logCritica) => {
                        return (
                            <>
                                <span className='titulo-aula-log'>{logCritica.tipo_critica.descricao}</span>
                                {
                                    logCritica.criticas?.map((critica) => {
                                        return (
                                            critica.horario_aula.id != id ?
                                                <ul className='ul-log'>
                                                    {
                                                        logCritica.tipo_critica_id == 3 ?
                                                            logCritica.grupos?.lenght == 0
                                                                ? <><span className='fonte-negrito'>Todos</span> os grupos estão alocados no mesmo dia e horário para a aula <span className='fonte-negrito'>{critica.horario_aula.aula.titulo}</span> para <span className='fonte-negrito'>{critica.semestre_periodo_letivo.periodo_letivo.descricao} {critica.semestre_periodo_letivo.semestre.descricao}</span> semestre <span className='fonte-negrito'>{critica?.semestre_periodo_letivo?.turma?.descricao}</span></>
                                                                : <>Os grupos {logCritica.grupos?.map((grupo) => { return (<><span className='fonte-negrito'>{grupo.grupo.descricao}</span>,</>) })} estão alocados no mesmo dia e horário para a aula <span className='fonte-negrito'>{critica.horario_aula.aula.titulo}</span> para <span className='fonte-negrito'>{critica.semestre_periodo_letivo.periodo_letivo.descricao} {critica.semestre_periodo_letivo.semestre.descricao}</span> semestre <span className='fonte-negrito'>{critica?.semestre_periodo_letivo?.turma?.descricao}</span></>
                                                            :
                                                            <>
                                                                {logCritica.tipo_critica_id == 1 ? 'O(A) Professor(a) ' : logCritica.tipo_critica_id == 2 ? 'O local ' : 'Grupo'}
                                                                <span className='fonte-negrito'>
                                                                    {logCritica.tipo_critica_id == 1 ?
                                                                        logCritica?.professor?.nome_social :
                                                                        logCritica?.local?.nome}
                                                                </span> está alocado no mesmo dia e horário
                                                                para a aula <span className='fonte-negrito'>{critica?.horario_aula?.aulas_id != null ? critica?.horario_aula?.aula?.titulo : critica?.horario_aula?.servico?.descricao}</span> para <span className='fonte-negrito'>{critica.semestre_periodo_letivo.periodo_letivo.descricao} {critica.semestre_periodo_letivo.semestre.descricao}</span> semestre <span className='fonte-negrito'>{critica?.semestre_periodo_letivo?.turma?.descricao}</span>
                                                            </>
                                                    }
                                                </ul> : critica.horario_aula.aulas_id == null ?
                                                    <ul className='ul-log'>
                                                        O(s) grupo(s) {logCritica.grupos?.map((grupo) => { return (<><span className='fonte-negrito'>{grupo.grupo.descricao}</span>, </>) })}
                                                        estão alocados no mesmo dia e horário para a aula.
                                                    </ul>
                                                    : "")

                                    })
                                }
                            </>
                        )
                    })}
                </div>
            </PopoverBody>
        </UncontrolledPopover >
    )
}

export default React.memo(PopOverCriticas);